import {request} from "@/network/request";

//-----------------------------------------------------用户登录Login-------------------------------------------------
// 用户登录
export function postUserLogin(data) {
  return request({
    method: 'post',
    url: '/api/Login/getlogin',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}

//-----------------------------------------------------用户操作User-------------------------------------------------
// 新增用户
export function postAddUser(token,data) {
  return request({
    method: 'post',
    url: '/api/User/AddUser',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

//修改用户信息
export function postEditUser(token,data) {
  return request({
    method: 'post',
    url: '/api/User/EditUser',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 获取用户列表
export function GetUserList(token,page,pagesize) {
  return request({
    method: 'GET',
    url: '/api/User/GetUserList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      page:page,
      pagesize:pagesize
    }
  })
}

// 获取登录用户信息详情
export function GetLoginUserDetail(token) {
  return request({
    method: 'GET',
    url: '/api/User/GetUser',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 获取某个用户的信息详情
export function GetUserDetailList(token,userid) {
  return request({
    method: 'GET',
    url: '/api/User/GetUserDetail',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      userid:userid
    }
  })
}

// 删除某个用户信息
export function PostDeleteUser(token,userid) {
  return request({
    method: 'Delete',
    url: '/api/User/DeleteUser',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      userid:userid
    }
  })
}

// 重置密码
export function ResetPwd(token,userid) {
  return request({
    method: 'GET',
    url: '/api/User/ResetPwd',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      userid:userid
    }
  })
}

// 搜索用户列表
export function GetSearchUser(token,page,pagesize,content) {
  return request({
    method: 'GET',
    url: '/api/User/GetSearchUser',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      page:page,
      pagesize:pagesize,
      content:content
    }
  })
}

// 根据userid获取角色
export function GetUserRole(token,userid) {
  return request({
    method: 'GET',
    url: '/api/User/GetUserRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      userid:userid
    }
  })
}

// 根据userid获取角色
export function GetUserAllRole(token) {
  return request({
    method: 'GET',
    url: '/api/User/GetUserAllRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 根据userid获取角色
export function AddUserRole(token,roleid,userid) {
  return request({
    method: 'GET',
    url: '/api/User/AddUserRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      roidlist:roleid,
      userid:userid
    }
  })
}

//-----------------------------------------------------获取部门信息-------------------------------------------------
// 根据单位id获取部门列表
export function GetDeptList(token,unitid) {
  return request({
    method: 'GET',
    url: '/api/User/GetDeptList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      unitid:unitid
    }
  })
}

// 获取所有部门列表
export function GetAllDeptList(token) {
  return request({
    method: 'GET',
    url: '/api/User/GetAllDeptList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 部门模糊搜索
export function GetSearchDeptList(token,unitid,content) {
  return request({
    method: 'GET',
    url: '/api/User/GetSearchDeptList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      unitid:unitid,
      content:content
    }
  })
}

// 新增部门
export function postAddDept(token,data) {
  return request({
    method: 'post',
    url: '/api/User/AddDept',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 编辑部门
export function postEditDept(token,data) {
  return request({
    method: 'post',
    url: '/api/User/EditDept',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 删除部门
export function DeleteDept(token,deptid) {
  return request({
    method: 'Delete',
    url: '/api/User/DeleteDept',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      deptid:deptid
    }
  })
}

//-----------------------------------------------------获取单位信息-------------------------------------------------
// 获取单位列表
export function GetUnitList(token) {
  return request({
    method: 'GET',
    url: '/api/User/GetUnitList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 搜索单位列表
export function GetSearchUnitList(token,content) {
  return request({
    method: 'GET',
    url: '/api/User/GetSearchUnitList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      content:content
    }
  })
}

// 新增单位
export function postAddUnit(token,data) {
  return request({
    method: 'post',
    url: '/api/User/AddUnit',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 编辑单位
export function postEditUnit(token,data) {
  return request({
    method: 'post',
    url: '/api/User/EditUnit',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 删除单位
export function DeleteUnit(token,unitid) {
  return request({
    method: 'Delete',
    url: '/api/User/DeleteUnit',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      unitid:unitid
    }
  })
}

//-----------------------------------------------------获取角色信息Role-------------------------------------------------
// 获取角色列表
export function GetAllRoleList(token) {
  return request({
    method: 'GET',
    url: '/api/Role/GetAllRoleList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 搜索角色列表
export function GetSearchRoleList(token,content) {
  return request({
    method: 'GET',
    url: '/api/Role/GetSearchRoleList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      content:content
    }
  })
}

// 新增角色
export function AddRole(token,data) {
  return request({
    method: 'post',
    url: '/api/Role/AddRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 编辑角色
export function EditRole(token,data) {
  return request({
    method: 'post',
    url: '/api/Role/EditRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 删除单位
export function DeleteRole(token,roleid) {
  return request({
    method: 'Delete',
    url: '/api/Role/DeleteRole',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      roleid:roleid
    }
  })
}

//-----------------------------------------------------获取菜单信息Role-------------------------------------------------
// 根据登录用户获取左侧菜单
export function GetUserMenu(token) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
  })
}

// 获取所有菜单-树形结构
export function GetTreeAllMenu(token) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetTreeAllMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
  })
}

// 根据角色id获取所有菜单
export function GetRoleMenu(token,roleid) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetRoleMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params: {
      roleid:roleid
    }
  })
}

// 新增菜单-角色
export function PostAddRoleMenu(token,data) {
  return request({
    method: 'post',
    url: '/api/Menu/PostAddRoleMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}


// 获取菜单列表
export function GetAllMenuList(token,page,pagesize) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetAllMenuList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      page:page,
      pagesize:pagesize
    }
  })
}

// 获取一级菜单列表
export function GetFirstMenuList(token) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetFirstMenuList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 搜索菜单列表
export function GetSearchMenuList(token,content,page,pagesize) {
  return request({
    method: 'GET',
    url: '/api/Menu/GetSearchMenuList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      content:content,
      page:page,
      pagesize:pagesize
    }
  })
}

// 新增菜单
export function AddMenu(token,data) {
  return request({
    method: 'post',
    url: '/api/Menu/AddMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 编辑菜单
export function EditMenu(token,data) {
  return request({
    method: 'post',
    url: '/api/Menu/EditMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 删除菜单
export function DeleteMenu(token,menuid) {
  return request({
    method: 'Delete',
    url: '/api/Menu/DeleteMenu',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      menuid:menuid
    }
  })
}

//-----------------------------------------------------获取编号信息Sysno-------------------------------------------------
// 获取编码列表
export function GetAllSysNoList(token) {
  return request({
    method: 'GET',
    url: '/api/Sysno/GetAllSysNoList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
  })
}

// 搜索编码列表
export function GetSearchSysNoList(token,content) {
  return request({
    method: 'GET',
    url: '/api/Sysno/GetSearchSysNoList',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      content:content
    }
  })
}

// 新增编码
export function AddSysNo(token,data) {
  return request({
    method: 'post',
    url: '/api/Sysno/AddSysNo',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 编辑编码
export function EditSysNo(token,data) {
  return request({
    method: 'post',
    url: '/api/Sysno/EditSysNo',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    data
  })
}

// 删除编码
export function DeleteSysNo(token,noid) {
  return request({
    method: 'Delete',
    url: '/api/Sysno/DeleteSysNo',
    headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
    params:{
      noid:noid
    }
  })
}