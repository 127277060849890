<template>
<div>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-select v-model="value" placeholder="请选择单位" @change="changeunit" clearable>
          <el-option
              v-for="item in filtunit"
              :key="item.value"
              :label="item.text"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-input placeholder="请输入部门名称搜索" v-model="deptname" clearable>
          <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <div></div>
      </el-col>
      <el-col :span="6" style="float: right;text-align: right">
        <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddDept">新增</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="Deptname" label="部门名称"></el-table-column>
          <el-table-column prop="Unitid" label="单位名称"
                           :formatter="formatter">
          </el-table-column>
          <el-table-column prop="Deptlevel" label="级别"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                <el-button
                    type="primary"
                    icon="el-icon-edit-outline"
                    circle
                    @click="handleEdit(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
              <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                <el-button
                    style="margin-left: 30px"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="handleDelete(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>

  <!--新增 弹出框操作begin-->
  <el-dialog
      title="新增部门"
      :visible.sync="dialogVisible"
      width="40%"
      @close="oncloseadd">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="单位" prop="unitid">
        <el-select v-model="addForm.unitid" placeholder="请选择单位">
          <el-option
              v-for="item in filtunit"
              :key="item.value"
              :label="item.text"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门名称" prop="deptname">
        <el-input v-model="addForm.deptname"></el-input>
      </el-form-item>
      <el-form-item label="级别" prop="deptlevel">
        <el-input-number v-model="addForm.deptlevel" @change="handleChange" :min="1" :max="10" label="级别">
        </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsave">确 定</el-button>
  </span>
  </el-dialog>
  <!--新增 弹出框操作end-->

  <!--编辑 弹出框操作begin-->
  <el-dialog
      title="修改用户"
      :visible.sync="editdialogVisible"
      width="40%"
      @close="oncloseedit">
    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
      <el-form-item label="单位" prop="unitid">
        <el-select v-model="editForm.unitid" placeholder="请选择单位">
          <el-option
              v-for="item in filtunit"
              :key="item.value"
              :label="item.text"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门名称" prop="deptname">
        <el-input v-model="editForm.deptname"></el-input>
      </el-form-item>
      <el-form-item label="级别" prop="deptlevel">
        <el-input-number v-model="editForm.deptlevel" @change="handleeditChange" :min="1" :max="10" label="级别">
        </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedit">确 定</el-button>
  </span>
  </el-dialog>
  <!--编辑 弹出框操作end-->
</div>
</template>

<script>
import {
  DeleteDept,
  GetAllDeptList,
  GetDeptList,
  GetSearchDeptList,
  GetUnitList,
  postAddDept,
  postAddUser, PostDeleteUser,
  postEditDept
} from "@/network/user";

export default {
  name: "Dept",
  data(){
    return{
      //部门
      deptname:'',
      //table数据
      tableData:[],
      //提示隐藏时间
      hideafter:3000,
      //单位数据value：text
      filtunit:[],
      //选择框选中的值
      value:'',
      //新增部门
      addForm:{
        unitid:'',
        deptname:'',
        deptlevel:'',
        superior:''//上级
      },
      //新增用户字段验证规则
      addFormRules:{
        deptname: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字', trigger: 'blur' }
        ],
        deptlevel: [
          { required: true, message: '请输入级别', trigger: 'blur' }
        ],
        unitid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ]
      },
      //是否显示新增对话框
      dialogVisible:false,
      //编辑部门
      editForm:{
        unitid:'',
        deptname:'',
        deptlevel:'',
        superior:'',//上级
        deptid:''
      },
      //编辑用户字段验证规则
      editFormRules:{
        deptname: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字', trigger: 'blur' }
        ],
        deptlevel: [
          { required: true, message: '请输入级别', trigger: 'blur' }
        ],
        unitid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ]
      },
      //是否显示编辑对话框
      editdialogVisible:false,
    }
  },
  created() {
    this.initdept()
  },
  methods:{
    //初始化加载部门列表
    init(){
      //获取所有部门
      GetAllDeptList(this.$store.state.token).then(res => {
        console.log(res);
        this.tableData =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    initdept(){
      this.init()
      //获取所有单位
      GetUnitList(this.$store.state.token).then(res => {
        this.filtunit=[]
        for (let item of res.data) {
          this.filtunit.push({text:item.Unitname,value:item.Id})
        }
        console.log(this.filtunit)
      }).catch(err => {
        console.log(err);
      })
    },
    //搜索按钮
    onSearch(){
      if (this.deptname !== '')
      GetSearchDeptList(this.$store.state.token,this.value,this.deptname).then(res => {
        this.tableData =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //新增部门
    onAddDept(){
      this.dialogVisible=true
    },
    //编辑部门
    handleEdit(index,row){
      this.editdialogVisible =true
      this.editForm.deptid =row.Id
      this.editForm.unitid =row.Unitid
      this.editForm.deptname=row.Deptname
      this.editForm.deptlevel =row.Deptlevel
    },
    //删除部门
    handleDelete(index,row){
      this.$confirm('此操作将删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteDept(this.$store.state.token,row.Id).then(res => {
          console.log(res)
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          if (this.deptname !== '')
          {
            GetSearchDeptList(this.$store.state.token,this.value,this.deptname).then(res => {
              this.tableData =res.data
            }).catch(err => {
              console.log(err);
            })
          }else
          {
            this.init()
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //格式化单位id为单位名称
    formatter(row, column){
      for (let item of this.filtunit) {
        if (item.value == row.Unitid)
          return item.text;
      }
    },
    //选中单位，数据变化
    changeunit(value){
      if (value===''){
        this.init()
      }else{
        GetDeptList(this.$store.state.token,value).then(res => {
          this.tableData =res.data
        }).catch(err => {
          console.log(err);
        })
      }
    },
    //计数器
    handleChange(value){
      this.addForm.deptlevel=value
    },
    //关闭新增对话框，清除验证
    oncloseadd(){
      this.$refs.addFormRef.resetFields()
    },
    //新增按钮，点击保存
    onsave(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('unitid', this.addForm.unitid);
        frmDate.append('deptname', this.addForm.deptname);
        frmDate.append('deptlevel', this.addForm.deptlevel);
        postAddDept(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            if (this.deptname !== '')
            {
              GetSearchDeptList(this.$store.state.token,this.value,this.deptname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //关闭编辑对话框，清除验证
    oncloseedit(){
      this.$refs.editFormRef.resetFields()
    },
    handleeditChange(value){
      this.editForm.deptlevel=value
    },
    onedit(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('deptid', this.editForm.deptid);
        frmDate.append('unitid', this.editForm.unitid);
        frmDate.append('deptname', this.editForm.deptname);
        frmDate.append('deptlevel', this.editForm.deptlevel);
        postEditDept(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            if (this.deptname !== '')
            {
              GetSearchDeptList(this.$store.state.token,this.value,this.deptname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    }
  }
}
</script>

<style scoped>
.el-table{
  margin: 20px 0px;
}
.el-select{
  width: 100%;
}
</style>